import { faUser } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import dynamic from 'next/dynamic'
import Router, { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { useContext, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { twMerge } from 'tailwind-merge'

import { RegisterOrSignInModal } from '../register-or-sign-in/register-or-sign-in-modal'
import { UserContext } from '../user-info-provider/user-context'
import { USER_PROFILE_URL } from '../../constants'

const FontAwesomeIcon: React.ComponentType<FontAwesomeIconProps> = dynamic(() =>
  import('@fortawesome/react-fontawesome').then((pkg) => pkg.FontAwesomeIcon)
)
export interface ProfileIconProps {
  className?: string
  containerClassName?: string
  isInversed?: boolean
}

/**
 *
 * Component used to display the users's profile image and navigate to the user's profile when clicked on.
 *
 */
export function ProfileIcon({ className = '', containerClassName = '', isInversed = false }: ProfileIconProps) {
  const { asPath } = useRouter()
  const [origin, setOrigin] = useState<string>('')
  const { isLoggedIn } = useContext(UserContext)
  const { t } = useTranslation()

  useEffect(() => {
    setOrigin(window.location.origin)
  }, [])

  const [isRegisterOrSignInModalOpen, setIsRegisterOrSignInModalOpen] = useState(false)
  const handleRegisterOrSignInClick = () => {
    if (isLoggedIn) {
      Router.push(USER_PROFILE_URL)
      return
    }

    if (isMobile) {
      Router.push(`/register-or-sign-in?redirectTo=${origin}${encodeURIComponent(asPath)}`)
    } else {
      setIsRegisterOrSignInModalOpen(true)
    }
  }
  const handleRegisterOrSignInClose = () => setIsRegisterOrSignInModalOpen(false)

  return (
    <>
      {/* TODO: This may need to be an <a> for SEO pruposes */}
      <button
        data-testid="profile-btn"
        className={twMerge('outline-none', containerClassName)}
        onClick={handleRegisterOrSignInClick}
        aria-label={t(`users:${!isLoggedIn ? 'register-or-sign-in' : 'my-account'}`)}
      >
        <div
          className={twMerge(
            `relative flex h-8 w-8 cursor-pointer items-center rounded-[50%] border`,
            `${isInversed ? 'border-none bg-white' : 'border-shades-200 bg-shades-25'}`,
            className
          )}
        >
          <div className={twMerge(`${isInversed ? 'text-primary' : 'text-shades-200'}`, 'mx-auto inline-block')}>
            <FontAwesomeIcon icon={faUser} />
          </div>
          {isLoggedIn ? (
            <span
              data-testid="logged-in"
              className="absolute bottom-0 right-0 h-3 w-3 rounded-full border-2 border-white bg-primary text-primary"
            />
          ) : null}
        </div>
      </button>
      <RegisterOrSignInModal isOpen={isRegisterOrSignInModalOpen} onClose={handleRegisterOrSignInClose} />
    </>
  )
}

export default ProfileIcon
