export function ListIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
      <path
        fill="#EED8DC"
        fillRule="evenodd"
        d="M.727 0A.727.727 0 0 0 0 .727v14.546c0 .401.326.727.727.727h14.546a.727.727 0 0 0 .727-.727V.727A.727.727 0 0 0 15.273 0H.727Z"
        clipRule="evenodd"
      />
      <path
        fill="#D35B70"
        fillRule="evenodd"
        d="M.873 15.127V.873h14.254v14.254H.873ZM0 .727C0 .326.326 0 .727 0h14.546c.401 0 .727.326.727.727v14.546a.727.727 0 0 1-.727.727H.727A.727.727 0 0 1 0 15.273V.727Zm3.622 4.8H4a.436.436 0 1 0 0-.873h-.378a.436.436 0 1 0 0 .873Zm2.56-.873a.436.436 0 1 0 0 .873h6.181a.436.436 0 1 0 0-.873H6.182Zm-2.56 2.91a.436.436 0 0 0 0 .872H4a.436.436 0 1 0 0-.873h-.378Zm2.56 0a.436.436 0 0 0 0 .872h6.181a.436.436 0 1 0 0-.873H6.182Zm-2.56 2.909a.436.436 0 0 0 0 .872H4a.436.436 0 0 0 0-.873h-.378Zm2.56 0a.436.436 0 0 0 0 .872h6.181a.436.436 0 0 0 0-.873H6.182Z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export default ListIcon
