import useTranslation from 'next-translate/useTranslation'

import { DEFAULT_LISTING_PAGE } from '@unreserved-frontend-v2/modules/listings/utils/routing/constants'
import { BuildingIcon } from '@unreserved-frontend-v2/ui/icons/building'
import { HouseDuotoneIcon } from '@unreserved-frontend-v2/ui/icons/house-duotone'
import { InfoCircleIcon } from '@unreserved-frontend-v2/ui/icons/info-circle'
import { ListIcon } from '@unreserved-frontend-v2/ui/icons/list'
import { MoneyIcon } from '@unreserved-frontend-v2/ui/icons/money'
import { QuestionMarkIcon } from '@unreserved-frontend-v2/ui/icons/question-mark'
import { TagYellowIcon } from '@unreserved-frontend-v2/ui/icons/tag-yellow'
import { MenuListItem } from '@unreserved-frontend-v2/ui/menu/menu'

export enum MainMenuKey {
  LEARN_MORE = 'learn-more',
  REAL_ESTATE = 'real-estate',
  SELL = 'learn-more/selling-simplified',
  MY_LISTINGS = 'my-listings',
  NONE = 'none',
}

export const useMenuItems = (activeMenuKey: MainMenuKey) => {
  const { t } = useTranslation('header')
  // const { hasToken, user } = useContext(UserContext)

  const subMenuItems = [
    {
      name: t('menu-about-us'),
      path: '/learn-more/about-us',
      icon: BuildingIcon,
      children: t('menu-about-us-desc'),
    },
    {
      name: t('menu-careers'),
      path: '/learn-more/career-opportunities',
      icon: ListIcon,
      children: t('menu-careers-desc'),
    },
    {
      name: t('menu-buying-simplified'),
      path: '/learn-more/buying-simplified',
      icon: MoneyIcon,
      children: t('menu-buying-simplified-desc'),
    },
    // {
    //   name: t('menu-cashback-details'),
    //   path: '/learn-more/cashback-details',
    //   icon: TagYellowIcon,
    //   children: '',
    // },
    {
      name: t('menu-selling-simplified'),
      path: '/learn-more/selling-simplified',
      icon: MoneyIcon,
      children: t('menu-selling-simplified-desc'),
    },
    {
      name: t('menu-contact-us'),
      path: '/learn-more/contact-us',
      icon: QuestionMarkIcon,
      children: t('menu-contact-us-desc'),
    },
  ]

  const menu: (MenuListItem | undefined)[] = [
    {
      name: t('menu-buy'),
      path: DEFAULT_LISTING_PAGE,
      icon: HouseDuotoneIcon,
      id: MainMenuKey.REAL_ESTATE,
      isActive: activeMenuKey === MainMenuKey.REAL_ESTATE,
      desc: t('menu-buy-desc'),
    },
    {
      name: t('menu-sell'),
      path: `/${MainMenuKey.SELL}`,
      icon: TagYellowIcon,
      id: MainMenuKey.SELL,
      isActive: activeMenuKey === MainMenuKey.SELL,
      desc: t('menu-sell-desc'),
    },
    // disabling menu item for now
    // !hasToken || user?.userType === UserType.Admin
    //   ? undefined
    //   : {
    //       name: t('menu-my-listings'),
    //       path: DEFAULT_MY_LISTING_PAGE,
    //       icon: TagYellowIcon,
    //       id: MainMenuKey.MY_LISTINGS,
    //       isActive: activeMenuKey === MainMenuKey.MY_LISTINGS,
    //     },
    {
      name: t('menu-learnmore'),
      path: '#',
      subItems: subMenuItems,
      icon: InfoCircleIcon,
      id: MainMenuKey.LEARN_MORE,
      desc: t('menu-learnmore-desc'),
      isActive: activeMenuKey === MainMenuKey.LEARN_MORE,
    },
  ].filter(Boolean)

  return menu as MenuListItem[]
}
