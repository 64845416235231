import { useContext } from 'react'
import dynamic from 'next/dynamic'
import { faUser } from '@fortawesome/pro-solid-svg-icons'
import { FlexRow } from '@unreserved-frontend-v2/ui/flex/flex-row'
import { FlexCol } from '@unreserved-frontend-v2/ui/flex/flex-col'
import { Button } from '@unreserved-frontend-v2/ui/button/button'
import { Spacer } from '@unreserved-frontend-v2/ui/spacer/spacer'
import { getUserName } from '../../utils/utils'
import { twMerge } from 'tailwind-merge'
import { faPowerOff } from '@fortawesome/pro-solid-svg-icons'
import useTranslation from 'next-translate/useTranslation'
import { emptyDefault } from '@unreserved-frontend-v2/utils/empty-default'
import { UserContext } from '../user-info-provider/user-context'
import Link from 'next/link'
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome'

const FontAwesomeIcon: React.ComponentType<FontAwesomeIconProps> = dynamic(
  () => import('@fortawesome/react-fontawesome').then((mod) => mod.FontAwesomeIcon),
  {
    ssr: false,
  }
)

export interface ProfileBasicProps {
  className?: string
}

export function ProfileBasic({ className = '' }: ProfileBasicProps) {
  const { t } = useTranslation('users')
  const { user } = useContext(UserContext)
  const iconContainerClasses = `bg-shades-25 border-shades-200 h-[40px] w-[40px] items-center justify-center rounded-[50%] border-2`

  return (
    <div className={twMerge('w-full', className)}>
      {!user ? (
        <Button data-testid="sign-up-button" className="mx-6" href="/register-or-sign-in" rel="nofollow">
          {t('register-or-sign-in')}
        </Button>
      ) : null}
      {user ? (
        <FlexRow className="border-shades-200 mb-3 items-center border-y py-[19px] px-6">
          <FlexRow className={iconContainerClasses}>
            <FontAwesomeIcon icon={faUser} className="text-shades-200 text-xl" />
          </FlexRow>
          <FlexCol className="pl-5">
            <span className="mb-[4px] font-bold">{getUserName(user)}</span>
            <span className="text-shades text-sm">{emptyDefault(user?.email)}</span>
          </FlexCol>
          <Spacer />
          <Link href="/users/logout">
            <FontAwesomeIcon icon={faPowerOff} className="text-xl text-red-600" />
          </Link>
        </FlexRow>
      ) : null}
    </div>
  )
}

export default ProfileBasic
