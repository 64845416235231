import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import { Breakpoint } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import React from 'react'

import { ValueHash } from '@unreserved-frontend-v2/utils/types'

import { CloseReason } from './types'
import { useTailwindConfig } from '../tailwind/use-tailwind-config'

export enum ModalVariants {
  Default = 'default',
  Unstyled = 'unstyled',
  UnstyledOverflow = 'unstyledOverflow',
}

export interface ModalProps {
  title?: string
  children: React.ReactNode
  isOpen: boolean
  isClosable?: boolean
  width?: string
  height?: string
  titleSx?: ValueHash<string>
  contentSx?: ValueHash<string>
  iconSx?: ValueHash<string>
  onClose: (event: React.MouseEvent<HTMLElement>, reason: string) => void
  /** See here for sizes: https://mui.com/material-ui/react-dialog/#optional-sizes */
  maxWidth?: Breakpoint
  variant?: ModalVariants
}

/**
 * @deprecated As this uses MUI
 */
export function Modal({
  title,
  isOpen,
  onClose,
  children,
  maxWidth = 'md',
  width = '',
  height = '',
  titleSx = {},
  contentSx = {},
  iconSx,
  isClosable = true,
  variant = ModalVariants.Default,
  ...rest
}: ModalProps) {
  const { shades } = useTailwindConfig()

  function onCloseInternal(event: React.MouseEvent<HTMLElement>, reason: string) {
    onClose(event, reason)
  }

  function handleCloseIconClick(event: React.MouseEvent<HTMLElement>) {
    onClose(event, CloseReason.CloseIconClick)
  }

  const titleSxCombined = {
    fontSize: '16px',
    fontWeight: '700',
    paddingTop: '20px',
    paddingBottom: '20px',
    paddingLeft: '32px',
    paddingRight: '32px',
    background: shades[25],
    borderBottom: '1px solid',
    borderBottomColor: 'grey.200',
    color: 'grey.600',
    ...titleSx,
  }

  const contentSxCombined = {
    borderColor: 'grey.200',
    borderRadius: '5px',
    textAlign: 'center',
    paddingTop: '2rem',
    padding: '',
    ...contentSx,
  }

  const combinedIconSx = {
    position: 'absolute',
    right: 18,
    top: 14,
    zIndex: 1,
    ...iconSx,
  }

  const paperSx = {
    ...(width ? { width } : {}),
    ...(height ? { height } : {}),
    padding: contentSxCombined.padding,
  }

  const variantsSx = {
    [ModalVariants.Default]: {
      paperSx,
      contentSxCombined,
    },
    [ModalVariants.Unstyled]: {
      paperSx: { padding: 0, border: 'none' },
      contentSxCombined: { padding: 0 },
    },
    [ModalVariants.UnstyledOverflow]: {
      paperSx: { padding: 0, border: 'none', overflow: 'unset' },
      contentSxCombined: { padding: 0 },
    },
  }

  const sxConfig = variantsSx[variant]

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={onCloseInternal}
        {...rest}
        data-testid="modal"
        maxWidth={maxWidth}
        fullWidth={true}
        PaperProps={{ sx: sxConfig.paperSx }}
      >
        {isClosable ? (
          <IconButton data-testid="close-x" aria-label="close" onClick={handleCloseIconClick} sx={combinedIconSx}>
            <FontAwesomeIcon icon={faCircleXmark} className="text-xl text-shades-200" />
          </IconButton>
        ) : null}
        {title ? <DialogTitle sx={titleSxCombined}>{title}</DialogTitle> : null}
        <DialogContent color="white" className="w-full" sx={sxConfig.contentSxCombined}>
          {children}
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default Modal
