import { twMerge } from 'tailwind-merge'

import { ProfileBasic } from '@unreserved-frontend-v2/modules/users/components/user-profile/profile-basic'
import { ProfileIcon } from '@unreserved-frontend-v2/modules/users/components/user-profile/profile-icon'
import { Logo } from '@unreserved-frontend-v2/ui/logo/logo'
import { MenuMobile } from '@unreserved-frontend-v2/ui/menu/menu-mobile'

import { AppHeaderVariants, VARIANTS } from './constants'
import { MainMenuKey, useMenuItems } from '../hooks/use-menu-items'

interface AppHeaderMobileProps {
  className?: string
  noBorder?: boolean
  variant?: AppHeaderVariants
}

export function AppHeaderMobile({
  className = '',
  noBorder = false,
  variant = AppHeaderVariants.DEFAULT,
}: AppHeaderMobileProps) {
  const menu = useMenuItems(MainMenuKey.REAL_ESTATE)
  const variantConfig = VARIANTS[variant]

  return (
    <div
      id="app-header-mobile"
      data-testid="app-header-mobile"
      className={twMerge(
        `no-scroll-when-overlay-open relative z-[10001] flex h-14 w-full items-center justify-between border-shades-200 px-6 pt-5`,
        variantConfig.className,
        className,
        noBorder ? 'pb-[21px]' : 'border-b pb-5'
      )}
    >
      <MenuMobile inverseMenuIcon={variantConfig.inverseElements} items={menu} bottomComponent={<ProfileBasic />} />
      <div className="flex-grow pt-1 text-center">
        {/* NOTE: The logo size was initially set to a width of 118,
            this has been removed due to the header now using the beta logo */}
        <Logo width="160" isInversed={variantConfig.inverseElements} />
      </div>
      <ProfileIcon isInversed={variantConfig.inverseElements} />
    </div>
  )
}

export default AppHeaderMobile
