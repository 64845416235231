export type ValueTypes = string | number | undefined | null

export const isValueEmpty = (value: ValueTypes): boolean => {
  return value === undefined || value === null || value === '' || Number.isNaN(value)
}

/**
 *
 * @returns true if value prop in every object in the array is empty
 */
export const isValueArrayEmpty = (arr: { value: ValueTypes }[]): boolean => {
  return !arr.find((item) => !isValueEmpty(item.value))
}
