import { SVGProps } from 'react'

export const TagYellowIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={18} height={18} fill="none" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M0 1.975C0 .885.884 0 1.975 0h6.152c.7 0 1.366.277 1.86.772l7.242 7.24a2.637 2.637 0 0 1 0 3.727l-5.49 5.49a2.637 2.637 0 0 1-3.728 0L.771 9.986A2.626 2.626 0 0 1 0 8.127V1.975Z"
      fill="#F6EED7"
    />
    <path
      d="M3.621 4.609a.986.986 0 1 1 1.975 0 .986.986 0 1 1-1.975 0ZM0 1.975C0 .885.884 0 1.975 0h6.152c.7 0 1.366.277 1.86.772l7.242 7.24a2.637 2.637 0 0 1 0 3.727l-5.49 5.49a2.637 2.637 0 0 1-3.728 0L.771 9.986A2.626 2.626 0 0 1 0 8.127V1.975Zm1.702 7.082 7.243 7.242a1.314 1.314 0 0 0 1.86 0l5.494-5.494a1.314 1.314 0 0 0 0-1.86L9.056 1.702a1.38 1.38 0 0 0-.93-.385H1.975a.658.658 0 0 0-.658.658v6.152c0 .312.138.683.385.93Z"
      fill="#F0C852"
    />
  </svg>
)
