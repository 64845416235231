import React, { useState, useEffect, useLayoutEffect } from 'react'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import Button from '../button/button'
import Menu from './menu.svg'
import MenuInverse from './menu-inverse.svg'
import { MenuListItem } from './menu'
import { useRouter } from 'next/router'
import { twMerge } from 'tailwind-merge'
import { HAVE_WINDOW } from '@unreserved-frontend-v2/utils/window'
import { FlexCol } from '../flex/flex-col'

export interface MenuMobileProps {
  items: MenuListItem[]
  bottomComponent?: React.ReactNode
  inverseMenuIcon?: boolean
}

export function MenuMobile({ items, bottomComponent, inverseMenuIcon = false }: MenuMobileProps) {
  const { t } = useTranslation('header')
  const [isOpen, setIsOpen] = useState(false)
  const router = useRouter()
  const offsetAmount = 55
  const [offsetTop, setOffsetTop] = useState(0)
  const useIsomorphicLayoutEffect = HAVE_WINDOW ? useLayoutEffect : useEffect

  const handleOpen = () => {
    document.body.classList.add('mobile-overlay-open')
    setIsOpen(true)
  }

  const handleClose = () => {
    document.body.classList.remove('mobile-overlay-open')
    setIsOpen(false)
  }

  useIsomorphicLayoutEffect(() => {
    setOffsetTop(() => offsetAmount - window?.scrollY)
  }, [isOpen])

  useEffect(() => {
    handleClose()
  }, [router])

  const menuItems = (
    <nav className="flex-1 overflow-auto border-t border-shades-200">
      {items.map(({ icon: Icon, ...item }) => (
        <div key={item.name} onClick={handleClose}>
          <Link href={item.path} className="flex items-center border-b border-shades-200 bg-white px-[24px] py-[20px]">
            <div className="flex h-[40px] w-[40px] items-center justify-center rounded bg-shades-25">
              {Icon ? <Icon /> : null}
            </div>
            <div className="pl-4">
              <span className="mb-4 font-bold">{item.name}</span>
              {item.desc ? <p className="text-sm text-shades">{item.desc}</p> : null}
            </div>
          </Link>

          {item.subItems ? (
            <div className="mt-3">
              {item.subItems.map(({ icon: SubIcon, ...subItem }) => {
                return (
                  <Link key={subItem.name} href={subItem.path} className="flex items-center px-[24px]">
                    <div className="flex h-[40px] w-[40px] items-center justify-center">
                      <SubIcon />
                    </div>
                    <div className="pl-4">
                      <p>{subItem.name}</p>
                    </div>
                  </Link>
                )
              })}
            </div>
          ) : null}
        </div>
      ))}
    </nav>
  )

  const MenuIcon = inverseMenuIcon ? MenuInverse : Menu

  return (
    <nav>
      <details
        className="[&[open]>.nojs-menu]:block"
        open={isOpen}
        onClick={(e) => {
          // When JS is available, state is handled in React
          e.preventDefault()
        }}
      >
        <summary
          data-testid="mobile-nav-open"
          onClick={handleOpen}
          className="w-8 list-none [&::-webkit-details-marker]:hidden"
        >
          <MenuIcon width={19} alt="Menu open button" />
        </summary>
        {isOpen ? (
          <div
            data-testid="mobile-nav-top-placeholder"
            onClick={handleClose}
            className="fixed top-0 left-0 right-0 bottom-0"
          />
        ) : null}
        <FlexCol
          data-testid="mobile-nav"
          className={twMerge('fixed left-0 bottom-0 w-full bg-shades-25', !isOpen ? 'nojs-menu hidden' : '')}
          style={{
            top: `${offsetTop}px`,
          }}
        >
          {menuItems}
          <div className="w-full flex-shrink-0">
            {bottomComponent}
            <div className="px-6 pt-2 pb-6">
              <Button data-testid="mobile-nav-close" variant="ghost" width="full" onClick={handleClose}>
                {t('menu-close')}
              </Button>
            </div>
          </div>
        </FlexCol>
      </details>
    </nav>
  )
}

export default MenuMobile
