export function MoneyIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" fill="none">
      <path
        fill="#DCD6E2"
        d="M0 2.237A2.23 2.23 0 0 1 2.222 0h15.556A2.23 2.23 0 0 1 20 2.237v8.947a2.232 2.232 0 0 1-2.222 2.237H2.222A2.23 2.23 0 0 1 0 11.184V2.237Z"
      />
      <path
        fill="#6D508D"
        d="M16.111 7.829a.559.559 0 0 1 0 1.118H9.444a.559.559 0 0 1 0-1.118h6.667ZM8.89 5.033c0-.308.25-.56.555-.56h6.667a.559.559 0 0 1 0 1.12H9.444a.559.559 0 0 1-.555-.56ZM5.524 3.69c.236.035.66.126.868.171.299.08.473.388.396.685a.559.559 0 0 1-.68.399 12.41 12.41 0 0 0-.768-.158c-.448-.07-.823-.014-1.073.102-.25.115-.375.262-.364.356-.025.175-.004.248.017.29a.43.43 0 0 0 .17.178c.236.161.594.273 1.077.42l.02.007c.428.13.97.293 1.372.587.215.16.417.37.542.657.125.29.149.612.093.95a1.702 1.702 0 0 1-1.017 1.248c-.194.084-.403.14-.621.172v.31c0 .308-.25.56-.556.56a.559.559 0 0 1-.556-.56V9.73c-.326-.063-.819-.23-1.126-.339a7.637 7.637 0 0 0-.16-.052.562.562 0 0 1-.352-.71.557.557 0 0 1 .704-.353c.035.025.14.05.212.074.33.112.719.244.941.251.462.105.834.06 1.077-.045.225-.098.326-.234.357-.412a.538.538 0 0 0-.017-.312.49.49 0 0 0-.177-.199c-.226-.168-.58-.283-1.056-.43l-.073-.02c-.41-.126-.923-.28-1.306-.542a1.583 1.583 0 0 1-.554-.626 1.536 1.536 0 0 1-.107-.93c.1-.583.511-.989 1.002-1.212.194-.091.41-.154.635-.193v-.325c0-.307.25-.559.556-.559.306 0 .556.252.556.56l-.032.335ZM0 2.237A2.23 2.23 0 0 1 2.222 0h15.556A2.23 2.23 0 0 1 20 2.237v8.947a2.232 2.232 0 0 1-2.222 2.237H2.222A2.23 2.23 0 0 1 0 11.184V2.237Zm1.111 0v8.947c0 .619.498 1.119 1.111 1.119h15.556c.614 0 1.11-.5 1.11-1.119V2.237c0-.619-.496-1.119-1.11-1.119H2.222c-.613 0-1.11.5-1.11 1.119Z"
      />
    </svg>
  )
}

export default MoneyIcon
