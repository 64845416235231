export function BuildingIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" fill="none">
      <path
        fill="#E5FBF0"
        fillRule="evenodd"
        d="M0 .4h9.962v6.038h2.114V4.627h.905v1.811H16V16.4h-3.925v-3.018H9.963V16.4H6.038v-3.018H3.925V16.4H0V.4Z"
        clipRule="evenodd"
      />
      <path
        fill="#1DBF73"
        fillRule="evenodd"
        d="M0 .4h9.962v6.038h2.114V4.627h.905v1.811H16V16.4h-3.925v-3.018H9.963V16.4H6.038v-3.018H3.925V16.4H0V.4Zm3.925 12.076h2.113v-2.113H3.925v2.113Zm-.906-2.113v2.113H.906v-2.113h2.113Zm.906-.906h2.113V7.344H3.925v2.113Zm-.906-2.113v2.113H.906V7.344h2.113Zm.906-.906h2.113V4.325H3.925v2.113Zm-.906-2.113v2.113H.906V4.325h2.113Zm.906-.906h2.113V1.306H3.925v2.113Zm-.906-2.113v2.113H.906V1.306h2.113Zm3.924 0v2.113h2.114V1.306H6.943Zm2.114 3.019H6.943v2.113h2.114V4.325Zm0 3.019H6.943v2.113h2.113V7.344Zm-.001 3.019H6.943v2.113h2.114l-.001-2.113Zm.906 2.113h3.02v3.019h2.112V7.344H9.962v5.132Zm-.905.905H6.943v2.114h2.114v-2.113Zm-8.151 0h2.113v2.114H.906v-2.113Zm11.17-2.716v-1.51h.905v1.51h-.905Z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export default BuildingIcon
