import { ConsumerUser, UserType } from '@unreserved-frontend-v2/api/generated/graphql/types'
import { Translate } from 'next-translate'
import { User } from '../types/user'

export function getUserName(user: User) {
  if (!user) {
    return '-'
  }

  const { firstName, lastName } = user
  if (firstName && lastName) {
    return `${firstName} ${lastName}`
  }

  if (firstName) {
    return firstName
  }

  if (lastName) {
    return lastName
  }

  return '-'
}

export function getUserInitials(user: User) {
  if (!user) {
    return '-'
  }

  const { firstName, lastName } = user

  if (firstName && lastName) {
    return `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase()
  }

  if (firstName) {
    return firstName.charAt(0).toUpperCase()
  }

  if (lastName) {
    return lastName.charAt(0).toUpperCase()
  }

  return '-'
}

const KEYS = {
  nouns: {
    buy: 'users:buyer',
    and: 'users:&',
    sell: 'users:seller',
    browse: 'users:browser',
  },
  verbs: {
    buy: 'users:buying',
    and: 'users:and',
    sell: 'users:selling',
    browse: 'users:im-just-looking',
  },
}

export function getUserInterestedIn(user: User, t: Translate, keySet: keyof typeof KEYS = 'verbs') {
  if (!user) {
    return '-'
  }

  const keys = KEYS[keySet]

  if (user.userType === UserType.Consumer) {
    const consumer = user as ConsumerUser

    if (consumer.isBuyer && consumer.isSeller) {
      return `${t(keys.buy)} ${t(keys.and)} ${t(keys.sell)}`
    }

    if (consumer.isBuyer) {
      return t(keys.buy)
    }

    if (consumer.isSeller) {
      return t(keys.sell)
    }
  }

  return t(keys.browse)
}
