export function HouseDuotoneIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" fill="none">
      <path
        fill="#ECF7F3"
        d="M10.598.156a.603.603 0 0 1 .81 0L21.8 9.531c.252.227.275.59.019.883-.188.258-.577.281-.83.02l-1.428-1.29v7.731c0 1.727-1.368 3.125-3.057 3.125H5.501c-1.687 0-3.056-1.398-3.056-3.125v-7.73l-1.43 1.289a.575.575 0 0 1-.862-.02c-.223-.293-.2-.656.054-.883L10.598.156Z"
      />
      <path
        fill="#43AA8B"
        d="M21.8 9.531c.252.227.275.59.019.883-.188.258-.577.281-.83.02l-1.428-1.29v7.731c0 1.727-1.368 3.125-3.057 3.125H5.501c-1.687 0-3.056-1.398-3.056-3.125v-7.73l-1.43 1.289a.575.575 0 0 1-.862-.02c-.223-.293-.2-.656.054-.883L10.598.156a.603.603 0 0 1 .81 0L21.8 9.531ZM5.5 18.75h2.446V12.5c0-.691.546-1.25 1.222-1.25h3.668c.676 0 1.222.559 1.222 1.25v6.25h2.445c1.013 0 1.834-.84 1.834-1.875v-8.8l-7.335-6.616-7.335 6.615v8.801c0 1.035.821 1.875 1.833 1.875Zm3.668 0h3.668V12.5H9.169v6.25Z"
      />
    </svg>
  )
}

export default HouseDuotoneIcon
