export function InfoCircleIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
      <circle cx="10" cy="10" r="9" fill="#EED8DC" />
      <path
        fill="#D35B70"
        fillRule="evenodd"
        d="M0 10C0 4.477 4.477 0 10 0s10 4.477 10 10-4.477 10-10 10S0 15.523 0 10Zm.91 0a9.09 9.09 0 1 0 18.18 0A9.09 9.09 0 0 0 .91 10Zm10.994-4.659a1.295 1.295 0 0 0-.909-.34 1.305 1.305 0 0 0-.91.34 1.086 1.086 0 0 0 0 1.646c.519.463 1.301.463 1.82 0a1.09 1.09 0 0 0 0-1.646Zm-.568 8.75a.972.972 0 0 1-.587-.132.61.61 0 0 1-.168-.5c.005-.153.023-.305.055-.455.03-.171.07-.341.118-.509l.536-1.845c.056-.183.092-.37.11-.56 0-.125.01-.226.018-.305.005-.05.009-.09.009-.122a1.195 1.195 0 0 0-.423-.95 1.777 1.777 0 0 0-1.2-.363 3.114 3.114 0 0 0-.91.15c-.32.1-.658.22-1.013.359l-.154.6c.104-.037.231-.078.377-.123.139-.041.282-.063.427-.064a.873.873 0 0 1 .577.141.66.66 0 0 1 .15.496c0 .153-.017.305-.05.454-.031.16-.072.328-.122.505l-.541 1.854a5.296 5.296 0 0 0-.105.523c-.021.15-.032.303-.032.455-.002.367.166.714.455.94.348.268.78.4 1.218.373.308.007.615-.038.91-.132.257-.088.6-.213 1.031-.377l.146-.573a2.518 2.518 0 0 1-.36.119c-.155.035-.314.049-.472.04Z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export default InfoCircleIcon
