export enum AppHeaderVariants {
  DEFAULT = 'default',
  GREEN = 'green',
}

export const VARIANTS = {
  [AppHeaderVariants.DEFAULT]: {
    className: 'bg-white',
    inverseElements: false,
  },
  [AppHeaderVariants.GREEN]: {
    className: 'bg-primary',
    inverseElements: true,
  },
}
