export function QuestionMarkIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" fill="none">
      <path
        fill="#E5FBF0"
        d="M10 0C4.459 0 .002 3.65.002 8.153c0 1.867.78 3.577 2.074 4.951-.583 1.548-1.798 2.857-1.818 2.871a.943.943 0 0 0-.18 1.02.85.85 0 0 0 .83.601c2.41 0 4.276-1.01 5.452-1.813 1.101.353 2.358.559 3.642.559 5.542 0 9.999-3.65 9.999-8.153S15.543 0 10 0Z"
      />
      <path
        fill="#1DBF73"
        d="M10.867 3.798H8.97c-1.16 0-2.105.945-2.105 2.101a.627.627 0 1 0 1.254 0c0-.466.38-.846.85-.846h1.9a1.015 1.015 0 0 1 .444 1.927l-1.603.828a.632.632 0 0 0-.337.557v1.078a.628.628 0 0 0 1.254 0v-.694l1.255-.651a2.27 2.27 0 0 0-1.015-4.3ZM10 11.011a.784.784 0 0 0-.783.784c0 .431.317.784.784.784a.763.763 0 0 0 .784-.784.764.764 0 0 0-.784-.784ZM10 0C4.459 0 .002 3.65.002 8.153c0 1.867.78 3.577 2.074 4.951-.583 1.548-1.798 2.857-1.818 2.871a.943.943 0 0 0-.18 1.02.85.85 0 0 0 .83.601c2.41 0 4.276-1.01 5.452-1.813 1.101.353 2.358.559 3.642.559 5.542 0 9.999-3.65 9.999-8.153S15.543 0 10 0Zm0 15.087c-1.107 0-2.204-.166-3.262-.5a1.241 1.241 0 0 0-1.1.17c-.867.638-2.295 1.384-4.026 1.555.435-.592 1.166-1.587 1.597-2.73l.005-.012c.168-.442.07-.906-.252-1.287-1.134-1.23-1.74-2.62-1.74-4.094 0-3.803 3.94-6.9 8.746-6.9 4.84 0 8.745 3.097 8.745 6.9 0 3.802-3.872 6.898-8.713 6.898Z"
      />
    </svg>
  )
}

export default QuestionMarkIcon
