import { Modal } from '@unreserved-frontend-v2/ui/modal/modal'
import { CloseReason } from '@unreserved-frontend-v2/ui/modal/types'
import { useCallback } from 'react'
import RegisterOrSignIn from './register-or-sign-in'

export interface RegisterOrSignInModalProps {
  isOpen: boolean
  onClose?: (reason: CloseReason) => void
}

export function RegisterOrSignInModal({ isOpen, onClose }: RegisterOrSignInModalProps) {
  const handleModalClose = useCallback(
    (_: unknown, reason: string): void => {
      onClose?.(reason as CloseReason)
    },
    [onClose]
  )

  const handleRegisterOrSignInClose = useCallback(
    (reason: CloseReason): void => {
      onClose?.(reason)
    },
    [onClose]
  )

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleModalClose}
      contentSx={{
        padding: '0px',
        textAlign: 'start',
      }}
      iconSx={{ right: '7px', top: '6px' }}
      maxWidth="xssm"
    >
      <RegisterOrSignIn
        className="bg-white px-8 pb-8"
        successClassname="pt-8"
        onClose={handleRegisterOrSignInClose}
        showTitle={true}
        fullscreen={false}
      />
    </Modal>
  )
}

export default RegisterOrSignInModal
